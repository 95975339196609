<template>
<div>
   <div class="row mt-2">
        <div class="form-group col-lg-4 col-md-12 col-sm-12">
            <label class="form-label">Genero</label>
            <select class="form-control" v-model="genero">
                <option value="F">F</option>
                <option value="M">M</option>
            </select>
        </div>
        <div class="form-group col-lg-4 col-md-12 col-sm-12">
            <label class="form-label">Edad</label>
            <input type="number" class="form-control" v-model="edad_u">
        </div>
        <div class="form-group col-lg-4 col-md-12 col-sm-12">
            <label class="form-label">Creatinina Sérica (mg/dl)</label>
            <input type="number" class="form-control" v-model="creatinina">
        </div>
        <div class="form-group col-lg-4 col-md-12 col-sm-12">
            <label class="form-label">Raza</label>
            <select class="form-control" v-model="raza">
                <option value="white_and_others">Blanco u otro</option>
                <option value="black">Negro</option>
            </select>
        </div>
   </div>
   <div class="row">
       <div class="col-6">
           Resultado TFG : {{this.puntaje}}
       </div>
   </div>
   <div class=" mt-2">
       
        <button class="btn btn-primary mx-2 btn-sm" @click="calcular();">Calcular</button>
    
    
        <button class="btn btn-success btn-sm" @click="guardar();">Guardar</button>
       
   </div>
</div>

</template>
<script>
import tazaFiltracionGlomerularService from '../../../../services/tazaFiltracionGlomerularService';
import {glomerularFiltracionRate} from '../../../../utils/utils';
import {isEmpty} from "lodash";
import Toast from '../../../../components/common/utilities/toast';
import dayjs from 'dayjs';


export default {
    props: ['idHistoria','finalizada','idUsuario','sexo','fecha_nacimiento'],
    data() {
        return {
            
            genero:'',
            raza:'',
            puntaje:'',
            riesgo:'',
            creatinina:'',
            id_tfg:''
            
        }
    },
    methods: {
        async calcular(){
            try {

                const gender = this.genero === 'F' ? 'female' : 'male';

                const gFR = glomerularFiltracionRate(
                    gender,
                    this.raza,
                    this.edad_u,
                    this.creatinina,
                )

                this.puntaje = gFR;
    
            } catch (e) {
                console.error(e);
                this.LoaderSpinnerHide();
                 Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            }
        },
        async guardar(){
            try {

                let tfg={
                    id_historia: this.idHistoria,
                    genero:this.genero,
                    edad:this.edad_u,
                    creatinina:this.creatinina,
                    raza:this.raza,
                    puntaje:this.puntaje,
                }

                this.LoaderSpinnerShow();

                if(this.id_tfg!==''){
                    tfg['id']=this.id_tfg;
                    await tazaFiltracionGlomerularService.update(tfg); 
                }else{
                    await tazaFiltracionGlomerularService.store(tfg); 
                    const response=await tazaFiltracionGlomerularService.showByHc(this.idHistoria);
                    this.id_tfg=response.data.id;
                }
                
                
                this.LoaderSpinnerHide();
                
                Toast.fire({
                    icon: 'success',
                    title: 'Datos guardados con exito'
                });

            } catch (e) {
                console.error(e);
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            }
        }
    },
    async created(){
        this.genero=this.sexo;
        
        if(this.finalizada==='N'){
            const response=await tazaFiltracionGlomerularService.showByHc(this.idHistoria);
            if(!isEmpty(response.data)){
                this.raza=response.data.raza;
                this.creatinina=response.data.creatinina;
                this.puntaje=response.data.puntaje;
                this.id_tfg=response.data.id;
            } 
        } 
    },
    computed : {
        edad_u : function(){
            if(isEmpty(this.fecha_nacimiento)) return 0;
            return dayjs().diff(this.fecha_nacimiento, 'year');
        },
    }
}
</script>