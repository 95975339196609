<template>
    <main>
        <div>
            <div class="card-header bg-light "><span><i class="fa fa-syringe"> </i> Tipo de Patologia</span> </div>
            <div class="card-body">
                <div class="form-row">
                    <div class="form-group col-md-12">
                        <table class="table table-bordered table-striped">
                            <tr>
                                <th>Patologia</th>
                                <th>{{respuesta}}</th>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import historiaTipopatologiaService from "../../../../services/historiaTipoPatologiaService";
import {isEmpty} from "lodash";
export default {
    props:['idHistoria','finalizada'],
    data () {
        return {
            respuesta:'',
        }
    },
    async created(){
        const response = await historiaTipopatologiaService.showByHc(this.idHistoria);
        if(!isEmpty(response.data)){
            this.respuesta=response.data.respuesta;
            this.id_historia_patologia=response.data.id;
        }
    }
}
</script>

