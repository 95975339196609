<template>
    <main>
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
                <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">RIESGO CARDIOVASCULAR</a>
            </li>
            <li class="nav-item" role="presentation">
                <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">COMPLICACIONES IDENTIFICADAS</a>
            </li>
            <li class="nav-item" role="presentation">
                <a class="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">EXAMEN PIES</a>
            </li>
        </ul>
        <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
            <indexRiesgoCardiovascular ref="indexRiesgoCardiovascular" :idHistoria="idHistoria" :finalizada="finalizada" :idUsuario="idUsuario"></indexRiesgoCardiovascular>
        </div>
        <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
           <complicacionesIdentificadas ref="complicacionesIdentificadas" :idHistoria="idHistoria" :finalizada="finalizada" :idUsuario="idUsuario"></complicacionesIdentificadas>
        </div>
        <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
            <examenPies ref="examenPies" :idHistoria="idHistoria" :finalizada="finalizada"></examenPies>
        </div>
        </div>
    
    </main>
    
</template>
<script>
import indexRiesgoCardiovascular from "../base/baseRiesgoCardiovascular/indexRiesgoCardiovascular";
import complicacionesIdentificadas from "../base/baseComplicacionesIdentificadas/complicacionesIdentificadas";
import examenPies from "../base/baseExamenPies/examenPies";
import {required} from "vuelidate/lib/validators";

export default {
    props: ['idHistoria','finalizada','idUsuario'],
    components:{indexRiesgoCardiovascular,complicacionesIdentificadas,examenPies},
    data(){
        return {
            riesgo : '',
        }
    },
    validations(){
        return {
            riesgo : {required},
        }
    },
   methods: {
        async save(){

            this.$refs.indexRiesgoCardiovascular.validate();
            //this.$refs.complicacionesIdentificadas.validate();
            //this.$refs.examenPies.validate();
            //this.$nextTick(() => {this.$refs.complicacionesIdentificadas.$v.touch();});
            //this.$nextTick(() => {this.$refs.examenPies.$v.touch();});
        

            if (!this.$refs.indexRiesgoCardiovascular.$v.$invalid) this.riesgo = 'S';
            //if (!this.$refs.complicacionesIdentificadas.$v.$invalid) this.complicaciones = 'S';
            //if (!this.$refs.examenPies.$v.$invalid) this.examenPies = 'S';
            
            this.$v.$touch();

            if(this.$v.$invalid) return;

            await this.$refs.indexRiesgoCardiovascular.retornar();
           //this.$nextTick(async () =>  { await this.$refs.complicacionesIdentificadas.save();});
           // this.$nextTick(async ()=>{ await this.$refs.examenPies.save();});
            
        }
    }
}
</script>

