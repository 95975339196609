<template>
    <div class=" ">
        <div class="card-header bg-light "><span><i class="fa fa-file-medical"> </i> Tasa Filtracion Glomerular</span> </div>
        <div class="card-body">
            <div class="row mt-2">     
                    <div class="col-6">Edad: {{edad_u}}</div>
                    <div class="col-6">Raza: <span v-if="raza==='black'">Negro</span><span v-else>Blanco</span></div>
                    <div class="col-6">Creatinina Sérica: {{creatinina}}</div>
                    <div class="col-6">Puntaje: {{puntaje}} (mg/dl)</div>
            </div>
        </div>
    </div>  
</template>
<script>
import tazaFiltracionGlomerularService from '../../../../services/tazaFiltracionGlomerularService';
import {isEmpty} from "lodash";


export default {
    props: ['idHistoria','idUsuario'],
    data() {
        return {
            
                genero:'',
                edad_u:'',
                creatinina:'',
                raza:'',
                puntaje:'',
                id_tfg:''
            
        }
    },
    async created(){
        this.genero=this.sexo;
            const response_findrisc=await tazaFiltracionGlomerularService.showByHc(this.idHistoria);
                if(!isEmpty(response_findrisc.data)){
                    this.edad_u=response_findrisc.data.edad;
                    this.raza=response_findrisc.data.raza;
                    this.creatinina=response_findrisc.data.creatinina;
                    this.puntaje=response_findrisc.data.puntaje;
                    this.id_tfg=response_findrisc.data.id;
                } 
            }
    

}
</script>