<template>
    <main>
        <div>
            <div class="card-header bg-light "><span><i class="fa fa-syringe"> </i> Complicaciones Identificadas</span> </div>
            <div class="card-body">
                <div class="form-row">
                    <div class="form-group col-md-12">
                        <table class="table table-striped table-sm table-bordered font-size-12">
                            <tr v-for="item in complicaciones_identificadas" :key="item.id">
                                <th>{{item.nombre}}</th>
                                <td>{{item.detalle}}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import complicacionesIdentificadasService from "../../../../services/complicacionesIdentificadasService";
import historiaComplicacionesIdentificadasService from "../../../../services/historiaComplicacionesIdentificadasService";
import {isEmpty} from 'lodash';
export default {
    props:['idHistoria'],
    data(){
        return {
            complicaciones_identificadas:[],
        }
    },
    methods:{
        async cargarComplicacionesIdentificadas(){
            const response = await complicacionesIdentificadasService.index();
            this.complicaciones_identificadas= response.data.map(x=>{
                return {
                    ...x,
                    detalle:''
                }
            });
        },
    },
    async created(){
        await this.cargarComplicacionesIdentificadas();
    
        const response = await historiaComplicacionesIdentificadasService.showByHc(this.idHistoria);
        if(!isEmpty(response.data)){
                response.data.forEach(x=>{
                let i = this.complicaciones_identificadas.findIndex(p => parseInt(p.id) === parseInt(x.id_comp));
                if(i!==-1)  this.complicaciones_identificadas[i].detalle = x.detalle;
            });
        }
        
    }
}
</script>
<style scoped>
.font-size-12 {
  font-size: 12px;
}
</style>