<template>
    <main>
        <div>
            <div class="card-header bg-light "><span><i class="fa fa-syringe"> </i> Examen Pies</span> </div>
            <div class="card-body">
                <div class="form-row">
                    <div class="form-group col-md-12">
                        <table class="table table-striped table-sm table-bordered font-size-12">
                            <tr v-for="item in examenPies" :key="item.id">
                                <th>{{item.nombre}}</th>
                                <td>{{item.detalle}}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import partesExamenPiesService from "../../../../services/partesExamenPiesService";
import historiaExamenPiesService from "../../../../services/historiaExamenPiesService";
import {isEmpty} from 'lodash';
export default {
    props:['idHistoria'],
    data(){
        return {
            examenPies:[],
        }
    },
   
    methods:{
        async cargarHistoriaExamen(){
            const response = await partesExamenPiesService.index();
            this.examenPies= response.data.map(x=>{
                return {
                    ...x,
                    detalle:''
                }
            });
        },
    },
    async created(){
        await this.cargarHistoriaExamen();
        
        const response = await historiaExamenPiesService.showByHc(this.idHistoria);
        if(!isEmpty(response.data)){
                response.data.forEach(x=>{
                let i = this.examenPies.findIndex(p => parseInt(p.id) === parseInt(x.id_parte));
                if(i!==-1)  this.examenPies[i].detalle = x.detalle;
            });
        }
        
    }
}
</script>
<style scoped>
.font-size-12 {
  font-size: 12px;
}
</style>